import { Injectable } from '@angular/core';
import mapboxgl from 'mapbox-gl';
import { GeoPoint } from '@freddy/models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MapPreloaderService {
  private map: mapboxgl.Map | null = null;

  constructor() {
    mapboxgl.accessToken = environment.mapbox.token;
  }

  private initBackgroundMap(): void {
    if (!this.map) {
      const canvas = document.createElement('canvas');
      canvas.width = 512;
      canvas.height = 512;

      this.map = new mapboxgl.Map({
        container: canvas,
        style: environment.mapbox.style.static.low,
        center: [0, 0],
        zoom: 0,
        interactive: false,
        attributionControl: false,
        preserveDrawingBuffer: false,
      });
    }
  }

  preloadTiles(geoPoint: GeoPoint): Promise<void> {
    return new Promise((resolve) => {
      if (!environment.mapbox.preload.enabled) {
        resolve();
        return;
      }
      this.initBackgroundMap();
      if (this.map)
        this.map.once('load', () => {
          const minZoom = 16;
          const maxZoom = 17;
          const radius = 2.5 / 111.32; // 2.5 km in degrees (approximately)

          const preloadBounds = new mapboxgl.LngLatBounds(
            [geoPoint.lon - radius, geoPoint.lat - radius],
            [geoPoint.lon + radius, geoPoint.lat + radius],
          );

          let currentZoom = minZoom;
          const preloadNextZoom = () => {
            if (currentZoom <= maxZoom) {
              this.map!.setZoom(currentZoom);
              this.map!.fitBounds(preloadBounds, { animate: false });
              this.map!.once('idle', () => {
                currentZoom++;
                preloadNextZoom();
              });
            } else {
              console.log('Tile preloading completed');
              resolve();
            }
          };

          preloadNextZoom();
        });

      this.map!.setCenter({ lng: geoPoint.lon, lat: geoPoint.lat });
    });
  }

  clearPreloadedTiles(): void {
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
  }
}
