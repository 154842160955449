import {
  Action,
  Actions,
  createSelector,
  ofAction,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { Injectable, inject } from '@angular/core';

import {
  AuthenticateUserAction,
  ConfirmLanguageAction,
  GameJoinRequestAction,
  ListenCommandsAction,
  ListenRouteActions,
  LoadingGameAssetsAction,
  NavigateToStartGameScreen,
  RedirectToAdminAction,
  SelectLanguageAction,
  SetTeamStateAction,
  SetTenantAndOrganizationAction,
  SetUserDataAction,
} from '../../connect/actions/connect.actions';
import { GuidUtils, LocalStorageService } from '@freddy/common';
import { Router } from '@angular/router';
import { delay, finalize, map, switchMap, tap } from 'rxjs/operators';
import { PositionService } from '../services/position.service';
import { Position } from '@capacitor/geolocation';
import {
  combineLatest,
  filter,
  from,
  merge,
  Observable,
  takeUntil,
  timer,
  withLatestFrom,
} from 'rxjs';
import {
  AttackTeamAction,
  CheckExistingTeamAction,
  CreateNewTeamAction,
  GoToEndGameAction,
  GoToMapAction,
  GoToMissionsCompletedAction,
  InitializeGameStateAction,
  JoinTeamAction,
  ListenAnswersAction,
  ListenGameAction,
  ListenGameEndAction,
  ListenTeamChallengesAction,
  ListenTeamsAction,
  ListenTeamsPositionsAction,
  ListenToPlayerPositionAction,
  OneTimeDisplayAction,
  StartDYIGameAction,
  YourTeamIsAttackedAction,
} from '../../game/actions/game.actions';
import { OrganizationAndTenant } from '../../connect/models/organization';
import { TeamRepository } from '../repository/team.repository';
import { GameRepository } from '../repository/game.repository';
import { TeamWithPosition } from '../../game/containers/map-view/map-view.component';
import { ListenChatChangesAction } from '../../chat/actions/chat.actions';
import {
  Game,
  GameStatus,
  Team,
  TeamRole,
  TeamState,
  User,
} from '@freddy/models';
import { OneTimeDisplayEnum } from '../../game/config/one-time-display.enum';

import { MonitoringSessionService } from '../services/monitoring/monitoring-session.service';
import { NavController } from '@ionic/angular/standalone';
import { topToBottomTransition } from '../utils/animation.utils';
import {
  Database,
  off,
  onChildChanged,
  ref,
  set,
} from '@angular/fire/database';
import { patch, updateItem } from '@ngxs/store/operators';
import { environment } from '../../../environments/environment';
import { ConnectState } from './connect.store';
import { HotToastService } from '@ngneat/hot-toast';
import { CommandService } from '../services/command.service';
import { GameEffectsService } from '../services/game-effect.service';
import { SoundTypeEnum } from '../../shared/models/Sound';
import { SoundService } from '../services/sound.service';
import { TranslateService } from '@ngx-translate/core';
import { GameStateModel } from '../../../../../freddy-admin/src/app/features/game/store/game.store';
import { add } from 'date-fns';

export const INGAME_STATE_TOKEN = new StateToken<InGameStateModel>('ingame');

export interface InGameStateModel {
  game?: Game;
  loading: boolean;
  currentPosition?: Position;
  organization: OrganizationAndTenant;
  oneTimeDisplay: {
    [key: string]: boolean;
  };
  teams: Team[];
  user?: User;
  currentRole?: TeamRole;
}

@State<InGameStateModel>({
  name: INGAME_STATE_TOKEN,
  defaults: {
    loading: false,
    organization: {
      tenantId: null,
      organizationSlug: '',
    },
    oneTimeDisplay: {},
    teams: [],
  },
})
@Injectable()
export class InGameState {
  private readonly store = inject(Store);
  private readonly router = inject(Router);
  private readonly positionService = inject(PositionService);
  private readonly teamRepository = inject(TeamRepository);
  private readonly gameRepository = inject(GameRepository);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly monitoringSessionService = inject(MonitoringSessionService);
  private navController = inject(NavController);
  private database = inject(Database);
  private toastService = inject(HotToastService);
  private commandService = inject(CommandService);
  private soundService = inject(SoundService);
  private gameEffectService = inject(GameEffectsService);
  private translate = inject(TranslateService);
  private actions$ = inject(Actions);

  @Selector()
  static loading(state: InGameStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static userUid(state: InGameStateModel): string | undefined {
    return state.user?.uid;
  }

  @Selector([InGameState.myTeam])
  static myTeamRole(myTeam: Team | undefined): TeamRole {
    return myTeam?.role || TeamRole.PLAYER;
  }

  @Selector()
  static hasStarted10MinutesAgo(state: InGameStateModel): boolean {
    if (!environment.production) {
      return true;
    }
    const currentTime = new Date();
    const gameStartTime = new Date(
      state.game!.plannedEndDate!.getTime() - state.game!.duration * 60000,
    );
    const tenMinutesAgo = new Date(currentTime.getTime() - 10 * 60000);
    return gameStartTime <= tenMinutesAgo;
  }

  @Selector()
  static plannedEndDate(state: InGameStateModel): Date | undefined | null {
    return state.game?.plannedEndDate;
  }

  @Selector()
  static gameStatus(state: InGameStateModel): GameStatus | undefined {
    return state.game?.status;
  }

  @Selector()
  static emergencyNumber(state: InGameStateModel): string | undefined {
    return state.game?.emergencyNumber;
  }

  @Selector()
  static gameIsDone(state: InGameStateModel): boolean {
    return (
      state.game?.status === GameStatus.DONE ||
      state.game?.status === GameStatus.STOPPED
    );
  }

  @Selector()
  static gamePath(state: InGameStateModel): string | null {
    return state.organization.organizationSlug && state.game?.uid
      ? `organizations/${state.organization.organizationSlug}/games/${state.game?.uid}`
      : null;
  }

  @Selector()
  static currentOrganizationSlug(state: InGameStateModel): string | undefined {
    return state.organization.organizationSlug;
  }

  @Selector([InGameState.gamePath, InGameState.myTeam])
  static teamPath(
    gamePath: string | null,
    team: Team | undefined,
  ): string | undefined {
    return gamePath && team?.uid ? `${gamePath}/teams/${team.uid}` : undefined;
  }

  @Selector()
  static game(state: InGameStateModel): Game | undefined {
    return state.game;
  }

  @Selector()
  static teams(state: InGameStateModel): Team[] {
    return state.teams;
  }

  @Selector()
  static players(state: InGameStateModel): Team[] {
    return state.teams.filter((team) => team.role === TeamRole.PLAYER);
  }

  static team(teamUid: string) {
    return createSelector([InGameState.teams], (teams: Team[]) => {
      return teams.find((team) => team.uid === teamUid);
    });
  }

  static oneTimeDisplay(oneTimeDisplayKey: OneTimeDisplayEnum) {
    return createSelector([InGameState], (state: InGameStateModel) => {
      return state?.oneTimeDisplay[oneTimeDisplayKey];
    });
  }

  @Selector()
  static currentPosition(state: InGameStateModel): Position | undefined {
    return state.currentPosition;
  }

  @Selector([InGameState.teams, InGameState.userUid])
  static myTeam(teams: Team[], userUid: string | undefined): Team | undefined {
    return teams.find((team) => team.userUid === userUid);
  }

  @Selector()
  static myTeamWithLatestPosition(
    state: InGameStateModel,
  ): TeamWithPosition | undefined {
    const myTeam = state.teams.find(
      (team) => team.userUid === state?.user?.uid,
    );
    return myTeam && state.currentPosition?.coords?.latitude
      ? {
          ...myTeam,
          currentPosition: state.currentPosition,
        }
      : undefined;
  }

  @Selector([InGameState.otherTeams])
  static othersTeamsWithPosition(
    teams: Team[],
  ): TeamWithPosition[] | undefined {
    return teams.filter(
      (team) => team.currentPosition && team.currentPosition?.coords.latitude,
    ) as TeamWithPosition[];
  }

  @Selector([InGameState])
  static otherTeams(state: InGameStateModel): Team[] {
    return state.teams.filter(
      (team) =>
        team.role === TeamRole.PLAYER &&
        team.userUid !== state?.user?.uid &&
        team,
    );
  }

  @Selector([InGameState, InGameState.players])
  static myRanking(state: InGameStateModel, players: Team[]): number {
    return (
      players
        .sort((a, b) => (b.points ?? 0) - (a.points ?? 0))
        .findIndex((team) => team.userUid === state?.user?.uid) + 1
    );
  }

  @Selector([InGameState])
  static user(state: InGameStateModel): User | undefined {
    return state.user;
  }

  @Action(ListenGameEndAction, { cancelUncompleted: true })
  listenGameEndAction(ctx: StateContext<GameStateModel>) {
    // Create our cancel$ stream that combines both cancellation conditions
    const cancel$ = merge(
      // Cancel when GoToEndGameAction is dispatched
      this.actions$.pipe(ofAction(GoToEndGameAction)),
    );

    return combineLatest([
      this.store.select(InGameState.game),
      this.store.select(InGameState.myTeam),
    ]).pipe(
      // Use our combined cancel$ stream
      takeUntil(cancel$),

      // Filter for the conditions we care about
      filter(([game, team]) => {
        const isGameEnded =
          game?.status === GameStatus.DONE ||
          game?.status === GameStatus.STOPPED;
        const isTeamAvailable = team?.currentState === 'AVAILABLE';
        return isGameEnded && isTeamAvailable;
      }),

      // Show the end game display first
      tap(() => {
        ctx.dispatch(new OneTimeDisplayAction(OneTimeDisplayEnum.END_GAME));
      }),

      // Wait 1 second
      delay(1000),

      // Finally dispatch GoToEndGameAction
      tap(() => {
        ctx.dispatch(new GoToEndGameAction());
      }),

      // Convert to void to match return type
      map(() => void 0),
    );
  }

  @Action(YourTeamIsAttackedAction)
  yourTeamIsAttackedAction(
    ctx: StateContext<InGameStateModel>,
    action: YourTeamIsAttackedAction,
  ) {
    this.soundService.playSound(SoundTypeEnum.WARNING_CLICK);
    this.toastService.show(
      this.translate.instant('game.actions.hunter.teamAttacked', {
        points: action.points,
      }),
      {
        duration: 5000,
        icon: '🔥',
      },
    );
    this.gameEffectService.triggerAttackEffects(false);
  }

  @Action(AttackTeamAction)
  async attackTeam(
    ctx: StateContext<InGameStateModel>,
    action: AttackTeamAction,
  ) {
    const state = ctx.getState();
    const myTeam = this.store.selectSnapshot(InGameState.myTeam);
    const attackPoints = state.game?.hunterAttackPoints ?? 3;

    const targetTeam = state.teams.find(
      (team) => team.uid === action.targetTeamUid,
    );

    if (!myTeam || !targetTeam || myTeam.role !== TeamRole.HUNTER) {
      return;
    }

    return combineLatest([
      this.teamRepository.update({
        uid: targetTeam.uid,
        points: (targetTeam.points || 0) - attackPoints,
      }),
      this.teamRepository.update({
        uid: myTeam.uid,
        lastAttacks: {
          ...myTeam.lastAttacks,
          [targetTeam.uid]: new Date(),
        },
      }),
      this.commandService.sendCommand({
        uid: GuidUtils.generateUuid(),
        recipientTeamUid: targetTeam.uid,
        action: new YourTeamIsAttackedAction(targetTeam.uid, attackPoints),
        executed: false,
      }),
    ]).pipe(
      this.toastService.observe({
        loading: this.translate.instant('game.actions.hunter.attacking'),
        success: this.translate.instant('game.actions.hunter.attackSuccess'),
        error: this.translate.instant('game.actions.hunter.attackFailed'),
      }),
      tap(() => {
        // Trigger confetti effects
        this.gameEffectService.triggerAttackEffects(
          myTeam.role === TeamRole.HUNTER,
        );
      }),
    );
  }

  @Action(GameJoinRequestAction)
  handleJoinRequest(
    ctx: StateContext<InGameStateModel>,
    action: GameJoinRequestAction,
  ) {
    const organizationSlug = action.organizationSlug;

    if (action.role === TeamRole.ADMIN) {
      return ctx.dispatch(
        new RedirectToAdminAction(action.game, organizationSlug),
      );
    }

    return this.store
      .dispatch(new AuthenticateUserAction(organizationSlug))
      .pipe(
        tap(() => {
          // Store essential information
          ctx.patchState({ game: action.game, currentRole: action.role });
          this.localStorageService.set('gameCode', action.game.code);
          this.localStorageService.set(
            'gamePlannedEndDate',
            action.game.plannedEndDate,
          );
          this.localStorageService.set('playerRole', action.role);
          // Start monitoring
          this.monitoringSessionService.startSession(action.game.uid);
        }),
        switchMap(() =>
          this.store.dispatch(new LoadingGameAssetsAction(action.game)),
        ),
        switchMap(() =>
          this.store.dispatch([
            new ListenTeamsAction(),
            new ListenGameAction(),
            new ListenToPlayerPositionAction(),
            new ListenTeamsPositionsAction(),
            new CheckExistingTeamAction(),
          ]),
        ),
      );
  }

  @Action(NavigateToStartGameScreen)
  navigateToStartGameScreen(ctx: StateContext<InGameStateModel>) {
    const game = ctx.getState().game;
    if (game) {
      const newPath =
        game.status === GameStatus.ONGOING
          ? ['game', game.code, game.companyLogo ? 'intro' : 'map']
          : ['game', game.code, 'teams'];
      return from(
        this.navController.navigateRoot(newPath, {
          replaceUrl: true,
        }),
      );
    }
    return from(
      this.navController.navigateRoot(['join'], {
        replaceUrl: true,
      }),
    );
  }

  @Action(InitializeGameStateAction, { cancelUncompleted: true })
  initializeGameState(
    ctx: StateContext<InGameStateModel>,
    action: InitializeGameStateAction,
  ) {
    return ctx.dispatch([
      new ListenAnswersAction(),
      new ListenTeamChallengesAction(),
      new ListenChatChangesAction(),
      new ListenCommandsAction(action.team),
      new ListenRouteActions(),
    ]);
  }

  @Action(ListenTeamsAction, { cancelUncompleted: true })
  listenTeamsAction(ctx: StateContext<InGameStateModel>) {
    const gamePath = this.store.selectSnapshot(InGameState.gamePath);

    if (!gamePath) {
      return;
    }
    const gameIsDone$ = this.store.select(InGameState.gameIsDone);
    return this.teamRepository.getCollectionsChanges().pipe(
      takeUntil(
        gameIsDone$.pipe(
          filter((isDone) => isDone),
          tap(() =>
            console.info(
              'Game is done, starting 30-minute timer for teams stream',
            ),
          ),
          switchMap(() => timer(30 * 60 * 1000)),
        ),
      ),
      filter((teams) => !!teams),
      map((teams) =>
        teams.map((team, index) => ({ ...team, teamNumber: index })),
      ),
      tap((updatedTeams) => {
        const currentTeams = ctx.getState().teams;
        const mergedTeams = updatedTeams.map((updatedTeam) => {
          const currentTeam = currentTeams.find(
            (t) => t.uid === updatedTeam.uid,
          );
          return {
            ...updatedTeam,
            currentPosition:
              currentTeam?.currentPosition || updatedTeam.currentPosition,
          };
        });

        console.debug('Updating state with teams:', mergedTeams);
        ctx.patchState({ teams: mergedTeams });
      }),
      finalize(() => {
        console.info('Unsubscribed from team updates');
      }),
    );
  }

  @Action(ListenGameAction, { cancelUncompleted: true })
  listenGameAction(
    ctx: StateContext<InGameStateModel>,
    action: ListenGameAction,
  ) {
    const gameUid = ctx.getState().game?.uid;
    if (gameUid) {
      return this.gameRepository.getDocChanges(gameUid).pipe(
        tap((game) => {
          console.trace('Received game update:', game);
          if (game) {
            ctx.patchState({
              game: game,
            });
          } else {
            console.error(
              'Game update is null or undefined, not updating state',
            );
          }
        }),
      );
    }
    return;
  }

  // @Throttle()
  @Action(CreateNewTeamAction)
  createNewTeamAction(
    ctx: StateContext<InGameStateModel>,
    action: CreateNewTeamAction,
  ) {
    const language = this.store.selectSnapshot(ConnectState.currentLang);

    if (!action.teamName || !action.teamPhotoPathBlob) {
      throw new Error('Team name or photo path blob is missing');
    }

    return this.teamRepository
      .createPlayerTeam(action.teamName, action.teamPhotoPathBlob, language)
      .pipe(
        switchMap((team) =>
          ctx.dispatch([
            new InitializeGameStateAction(team),
            new NavigateToStartGameScreen(),
          ]),
        ),
      );
  }

  @Action(SetUserDataAction)
  setUserDataAction(
    ctx: StateContext<InGameStateModel>,
    action: SetUserDataAction,
  ) {
    ctx.patchState({
      user: action.user,
    });
  }

  @Action(SetTenantAndOrganizationAction)
  setTenantAndOrganizationAction(
    ctx: StateContext<InGameStateModel>,
    action: SetTenantAndOrganizationAction,
  ) {
    ctx.patchState({
      organization: action.organization,
    });
  }

  @Action(SetTeamStateAction)
  setTeamStateAction(
    ctx: StateContext<InGameStateModel>,
    action: SetTeamStateAction,
  ) {
    const teamUid = this.store.selectSnapshot(InGameState.myTeam)?.uid;
    if (teamUid) {
      ctx.setState(
        patch({
          teams: updateItem<Team>(
            (team) => team.uid === teamUid,
            patch({
              currentState: action.teamState,
              online: true,
            }),
          ),
        }),
      );
    }
    return;
  }

  @Action(ConfirmLanguageAction)
  async handleLanguageConfirmation(ctx: StateContext<InGameStateModel>) {
    const role = ctx.getState().currentRole;
    const game = ctx.getState().game;
    const language = this.store.selectSnapshot(ConnectState.currentLang);

    if (!role || !game) {
      throw new Error('Game or role not found');
    }

    if (role === TeamRole.PLAYER) {
      return this.router.navigate(['game', game.code, 'teams']);
    }

    // Special roles get auto-created team after language selection
    const team = await this.teamRepository.createSpecialRoleTeam(
      role,
      language,
    );
    return ctx.dispatch([
      new InitializeGameStateAction(team),
      new NavigateToStartGameScreen(),
    ]);
  }

  @Action(ListenTeamsPositionsAction, { cancelUncompleted: true })
  listenTeamsPositionsAction(ctx: StateContext<InGameStateModel>) {
    const gameId = ctx.getState().game?.uid;

    if (!gameId) {
      console.error('No game ID available, cannot listen for team positions');
      return;
    }

    const positionsRef = ref(this.database, `games/${gameId}/positions`);

    return new Observable<void>((observer) => {
      const onPositionChange = onChildChanged(positionsRef, (snapshot) => {
        const userId = snapshot.key;
        const position = snapshot.val();

        if (userId && position) {
          ctx.setState(
            patch({
              teams: updateItem<Team>(
                (team) => team.userUid === userId,
                patch({
                  currentPosition: position,
                  online: true,
                  lastSeen: new Date().getTime(),
                }),
              ),
            }),
          );
          observer.next(); // Signal that we've processed an update
        }
      });

      return () => {
        off(positionsRef, 'child_changed', onPositionChange);
        observer.complete(); // Properly complete the observable
        console.info(
          `Unsubscribed from team position updates for game ${gameId}`,
        );
      };
    });
  }

  @Action(ListenRouteActions, { cancelUncompleted: true })
  listenRouteActions(ctx: StateContext<InGameStateModel>) {
    const gameId = ctx.getState().game?.uid;

    if (!gameId) {
      console.error('No game ID available, cannot listen for team positions');
      return;
    }

    const positionsRef = ref(this.database, `games/${gameId}/status`);

    return new Observable<void>((observer) => {
      const onPositionChange = onChildChanged(positionsRef, (snapshot) => {
        const userUid = snapshot.key;
        const status: { currentPath: string; currentState: TeamState } =
          snapshot.val();

        if (userUid && status) {
          ctx.setState(
            patch({
              teams: updateItem<Team>(
                (team) => team.userUid === userUid,
                patch({
                  ...status,
                  online: true,
                  lastSeen: new Date().getTime(),
                }),
              ),
            }),
          );
          console.trace(
            `Updated status for team ${userUid} in game ${gameId}`,
            status,
          );
        }
      });

      return () => {
        off(positionsRef, 'child_changed', onPositionChange);
        console.info(
          `Unsubscribed from team status updates for game ${gameId}`,
        );
      };
    });
  }

  @Action(ListenToPlayerPositionAction, { cancelUncompleted: true })
  listenToPlayerPositionAction(ctx: StateContext<InGameStateModel>) {
    const gameId = ctx.getState().game?.uid;

    const myTeam$ = this.store.select(InGameState.myTeam);

    return this.positionService.getPosition(1000).pipe(
      withLatestFrom(myTeam$),
      filter(([position, myTeam]) => !!myTeam?.userUid),
      tap(([position, myTeam]) => {
        if (!myTeam || !position || !position.coords) {
          console.log('Invalid position received, skipping update');
          return;
        }
        const positionRef = ref(
          this.database,
          `games/${gameId}/positions/${myTeam.userUid}`,
        );
        set(positionRef, {
          timestamp: position.timestamp,
          coords: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
            altitude: position.coords.altitude,
            altitudeAccuracy: position.coords.altitudeAccuracy,
            heading: position.coords.heading,
            speed: position.coords.speed,
          },
        });
        ctx.patchState({ currentPosition: position });
      }),
      finalize(() => {
        console.log('Unsubscribed from position updates');
      }),
    );
  }

  @Action(GoToMapAction)
  goToMapAction(ctx: StateContext<InGameStateModel>, action: GoToMapAction) {
    const game = ctx.getState().game;
    return from(
      this.navController.navigateBack(['game', game?.code, 'map'], {
        animation: topToBottomTransition,
      }),
    );
  }

  @Action(GoToEndGameAction)
  async goToEndGameAction(ctx: StateContext<InGameStateModel>) {
    const game = ctx.getState().game;
    await this.monitoringSessionService.endSession(
      this.store.selectSnapshot(InGameState.myTeam)!.uid,
    );
    return from(this.router.navigate(['game', game?.code, 'end']));
  }

  @Action(GoToMissionsCompletedAction)
  goToMissionsCompletedAction(ctx: StateContext<InGameStateModel>) {
    // TODO: Fix to ensure sending the message only once
    const game = ctx.getState().game;
    return from(
      this.router.navigate(['game', game?.code, 'missions-completed']),
    );
  }

  @Action(OneTimeDisplayAction)
  oneTimeDisplayAction(
    ctx: StateContext<InGameStateModel>,
    action: OneTimeDisplayAction,
  ) {
    const state = ctx.getState();
    state.oneTimeDisplay[action.key] = true;
    ctx.patchState({
      oneTimeDisplay: state.oneTimeDisplay,
    });
  }

  @Action(JoinTeamAction)
  joinTeamAction(ctx: StateContext<InGameStateModel>, action: JoinTeamAction) {
    return this.teamRepository
      .update({
        uid: action.team.uid,
        userUid: this.store.selectSnapshot(InGameState.userUid),
        online: true,
        lastSeen: new Date().getTime(),
      })
      .pipe(
        switchMap(() => this.store.dispatch(new CheckExistingTeamAction())),
      );
  }

  @Action(CheckExistingTeamAction)
  checkExistingTeam(ctx: StateContext<InGameStateModel>) {
    const userUid = this.store.selectSnapshot(InGameState.userUid) ?? '';
    return this.teamRepository.getTeamByUserUid(userUid).pipe(
      switchMap((existingTeam) => {
        if (existingTeam && existingTeam.language) {
          return ctx.dispatch([
            new InitializeGameStateAction(existingTeam),
            new SelectLanguageAction(existingTeam.language),
            new NavigateToStartGameScreen(),
          ]);
        }
        // No existing team, proceed to language selection
        return from(this.router.navigate(['game', ctx.getState().game?.code]));
      }),
    );
  }

  @Action(StartDYIGameAction)
  startGame(ctx: StateContext<InGameStateModel>) {
    const game = ctx.getState().game;

    if (!game || (!game.isDiyMode && !game.isTestMode)) {
      return;
    }

    const plannedEndDate = add(new Date(), { minutes: game.duration ?? 0 });

    return this.gameRepository.update({
      uid: game.uid,
      status: GameStatus.ONGOING,
      plannedEndDate: plannedEndDate,
      pausedAt: null,
    });
  }
}
